type Props = {
  date?: string | null;
  time?: boolean;
  relative?: boolean;
  relativeDays?: boolean;
  reduced?: boolean;
  at?: boolean;
};

export default function useFormatDate() {
  const { t, locale } = useI18n();
  return (props: Props) =>
    formatDate({
      yesterday: t("datesAndTimes.yesterday"),
      today: t("datesAndTimes.today"),
      locale: locale.value,
      ...props
    });
}
